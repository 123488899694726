import React, { useEffect } from "react"
import { useIntl, Link } from "gatsby-plugin-intl"
import Layout from "../components/Layout.jsx"
import Seo from "../components/Seo"
import VideoHero from "../assets/videos/video-hero.mp4"
import VideoInternational from "../assets/videos/video-international.mp4"
import ArrowRight from "../assets/images/icons/arrow-right.png"
import AboutForex from "../assets/images/about-forex.png"
import TradingComputer from "../assets/images/trading-image.png"
import SecurityShield from "../assets/images/icons/security.png"
import WhyFunctions from "../assets/images/icons/why-image1.png"
import WhyWithdrawals from "../assets/images/icons/why-image2.png"
import WhySupport from "../assets/images/icons/why-image3.png"
import WhyPlatform from "../assets/images/icons/why-image4.png"
import Register from "../assets/images/icons/register.png"
import Verify from "../assets/images/icons/verify.png"
import Deposit from "../assets/images/icons/deposit.png"
import Trade from "../assets/images/icons/trade.png"
import IconScroll from "../assets/images/icons/scroll-top-icon.svg"

import "../styles/pages/Index.scss"

export default function Index() {
  const intl = useIntl()
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <Layout>
      <Seo title="Inicio" />
      <section className="hero">
        <video
          id="video"
          src={VideoHero}
          autoPlay
          muted
          playsInline
          loop
          disablePictureInPicture
          className="hero__video"
        />
        <div className="hero__container">
          <h1 className="hero__title">
            {intl.formatMessage({ id: "hero_title" })}
          </h1>
          <h2 className="hero__subtitle">
            {intl.formatMessage({ id: "hero_subtitle" })}
          </h2>
          <div className="hero__actions">
            <Link to="/market" className="hero__button hero__button--first">
              {intl.formatMessage({ id: "button_learn" })}
              <img src={ArrowRight} alt="Arrow" className="hero__arrow" />
            </Link>
            <a
              href="https://clientzone.Adrswap.com/login"
              target="_blank"
              rel="noopener noreferrer"
              className="hero__button"
            >
              {intl.formatMessage({ id: "button_start" })}
              <img src={ArrowRight} alt="Arrow" className="hero__arrow" />
            </a>
          </div>
        </div>
      </section>
      <section className="about">
        <div className="about__container">
          <div className="about__box about__box--text">
            <h2 className="about__title">
              {intl.formatMessage({ id: "about_title" })}
            </h2>
            <p className="about__text">
              {intl.formatMessage({ id: "about_paragraphs" })}
            </p>
          </div>
          <div className="about__box">
            <img src={AboutForex} alt="About" className="about__image" />
          </div>
        </div>
      </section>
      <section className="trading">
        <div className="trading__container">
          <h2 className="trading__title">
            {intl.formatMessage({ id: "trading_title" })}
          </h2>
          <p className="trading__text">
            {intl.formatMessage({ id: "trading_paragraph" })}
          </p>
          <img src={TradingComputer} alt="Investment" className="trading__image" />
        </div>
      </section>
      <section className="international">
        <div className="international__container">
          <video
            id="video"
            src={VideoInternational}
            autoPlay
            muted
            playsInline
            loop
            disablePictureInPicture
            className="international__video"
          />
          <div className="international__box">
            <h2 className="international__title">
              {intl.formatMessage({ id: "international_title" })}
            </h2>
            <p className="international__text">
              {intl.formatMessage({ id: "international_subtitle" })}
            </p>
            <Link to="/market" className="international__button">
              {intl.formatMessage({ id: "button_learn" })}
              <img src={ArrowRight} alt="" className="international__arrow" />
            </Link>
          </div>
        </div>
      </section>
      <section className="security">
        <h2 className="security__title">
          <img src={SecurityShield} alt="Shield" className="security__icon" />{" "}
          {intl.formatMessage({ id: "security_title" })}
        </h2>
        <p className="security__text">
          {intl.formatMessage({ id: "security_paragraph" })}
        </p>
      </section>
      <section className="why">
        <div className="why__container">
          <h2 className="why__title">
            {intl.formatMessage({ id: "why_title" })}
          </h2>
          <div className="why__grid">
            <article className="why__item">
              <img src={WhyFunctions} alt="Functions" className="why__icon" />
              <h5 className="why__name">
                {intl.formatMessage({ id: "why_box_title1" })}
              </h5>
              <p className="why__text">
                {intl.formatMessage({ id: "why_box_subtitle1" })}
              </p>
            </article>
            <article className="why__item">
              <img
                src={WhyWithdrawals}
                alt="Withdrawals"
                className="why__icon"
              />
              <h5 className="why__name">
                {intl.formatMessage({ id: "why_box_title2" })}
              </h5>
              <p className="why__text">
                {intl.formatMessage({ id: "why_box_subtitle2" })}
              </p>
            </article>
            <article className="why__item">
              <img src={WhySupport} alt="Support" className="why__icon" />
              <h5 className="why__name">
                {intl.formatMessage({ id: "why_box_title3" })}
              </h5>
              <p className="why__text">
                {intl.formatMessage({ id: "why_box_subtitle3" })}
              </p>
            </article>
            <article className="why__item">
              <img src={WhyPlatform} alt="" className="why__icon" />
              <h5 className="why__name">
                {intl.formatMessage({ id: "why_box_title4" })}
              </h5>
              <p className="why__text">
                {intl.formatMessage({ id: "why_box_subtitle4" })}
              </p>
            </article>
          </div>
        </div>
      </section>
      <section className="start">
        <div className="start__container">
          <h1 className="start__title">
            {intl.formatMessage({ id: "how_title" })}
          </h1>
          <div className="start__grid">
            <article className="start__item">
              <img src={Register} alt="Register" className="start__icon" />
              <h5 className="start__name">
                {intl.formatMessage({ id: "how_box_title1" })}
              </h5>
              <p className="start__text">
                {intl.formatMessage({ id: "how_box_text1" })}
              </p>
            </article>
            <article className="start__item">
              <img src={Verify} alt="Verify" className="start__icon" />
              <h5 className="start__name">
                {intl.formatMessage({ id: "how_box_title2" })}
              </h5>
              <p className="start__text">
                {intl.formatMessage({ id: "how_box_text2" })}
              </p>
            </article>
            <article className="start__item">
              <img src={Deposit} alt="Deposit" className="start__icon" />
              <h5 className="start__name">
                {intl.formatMessage({ id: "how_box_title3" })}
              </h5>
              <p className="start__text">
                {intl.formatMessage({ id: "how_box_text3" })}
              </p>
            </article>
            <article className="start__item">
              <img src={Trade} alt="Trade" className="start__icon" />
              <h5 className="start__name">
                {intl.formatMessage({ id: "how_box_title4" })}
              </h5>
              <p className="start__text">
                {intl.formatMessage({ id: "how_box_text4" })}
              </p>
            </article>
          </div>
          <Link to="/market" className="start__button">
            {intl.formatMessage({ id: "button_learn" })}
            <img src={ArrowRight} alt="" className="start__arrow" />
          </Link>
        </div>
      </section>
      <button id="scroll-btn" className="up__button" title="Scroll to top">
        <img src={IconScroll} alt="Up" className="up__image" />
      </button>
    </Layout>
  )
}
